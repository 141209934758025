import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import Router from './Router';
import GlobDataProvider from './Context/GlobDataProvider';
import './index.css';
import './i18n';
import gsap from 'gsap';
import { ScrollToPlugin, ScrollTrigger } from 'gsap/all';


gsap.registerPlugin(ScrollToPlugin, ScrollTrigger);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
    // <React.StrictMode>
    <GlobDataProvider>
        <Router />
    </GlobDataProvider>
    // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
